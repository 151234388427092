@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* -------------------- components -------------------- */
.component-background {
  @apply w-screen min-h-screen bg-no-repeat bg-cover bg-center lg:bg-fixed
}

/* -------------------- heroComponent -------------------- */
.heroArrow {
  animation: animation 1000ms linear infinite both;
}



.animation-target {
  animation: animation 2000ms linear infinite both;
}

@keyframes animation { 
  0% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  6.31% { transform: matrix3d(1.122, 0, 0, 0, 0, 1.122, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  12.51% { transform: matrix3d(1.148, 0, 0, 0, 0, 1.148, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  23.52% { transform: matrix3d(1.113, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  34.43% { transform: matrix3d(1.064, 0, 0, 0, 0, 1.064, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  45.35% { transform: matrix3d(1.032, 0, 0, 0, 0, 1.032, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  56.26% { transform: matrix3d(1.014, 0, 0, 0, 0, 1.014, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  78.18% { transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% { transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } 
}



/* -------------------- musicComponent -------------------- */
.musicCard {
  @apply md:card-side bg-arsenic shadow-xl w-4/5 lg:w-2/3 xl:w-3/5 2xl:w-2/5 lg:max-h-96 min-h-min text-spanishWhite opacity-95 border-irisBlue border-2
}

.musicCard img {
  @apply w-full md:w-80 h-72 md:h-full object-cover
}

.musicCard h2 {
  @apply text-xl lg:text-2xl font-bold
}

.musicCard a {
  @apply my-1 first-letter:text-xl
}


/* -------------------- aboutSection -------------------- */
#aboutSection {
  @apply flex flex-col justify-center items-center
}

.aboutTab {
  @apply opacity-95 border-carminePink border-2 collapse collapse-arrow bg-arsenic text-spanishWhite shadow-xl w-5/6 lg:w-2/3 xl:w-3/5 2xl:w-2/5 min-h-min 
}

.aboutTabTop {
  @apply rounded-tl-lg rounded-tr-lg border-b-0
}

.aboutTabBottom {
  @apply rounded-bl-lg rounded-br-lg border-t-0
}

.aboutTab p {
  @apply mb-1
}

/* -------------------- photosSection -------------------- */
#photosSection {
  @apply flex flex-col justify-center items-center py-12
}

#photosSection img {
  @apply w-96
}

/* -------------------- contactComponents -------------------- */
#contactComponent {
  @apply flex flex-col justify-center items-center py-12
}

#contactComponent form {
  @apply bg-spanishWhite rounded-xl mb-16 p-12 flex flex-col justify-center items-center shadow-xl opacity-95 text-arsenic
}

#contactComponent button {
  @apply btn border-2 bg-spanishWhite text-irisBlue border-irisBlue hover:bg-irisBlue hover:border-irisBlue hover:text-spanishWhite
}

/* -------------------- footer -------------------- */

footer {
  @apply container mx-auto lg:flex justify-between items-center p-5 bg-arsenic
}

.social-media-icon {
  @apply mx-3 text-3xl text-rolling-stone hover:text-loblolly
}